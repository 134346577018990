/* eslint-disable tailwindcss/no-custom-classname */
import React, { Component } from 'react';
import classnames from 'classnames';
import { Hit, InfiniteHitsProvided, StateResultsProvided } from 'react-instantsearch-core';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ProductTile from './ProductTile';
import { bindColors } from '../../../../../../helpers/productHelpers';
import { ReactComponent as LoadingIcon } from '../../../../../icons/sport2000-icons/loading.svg';
import OldTagManager from '../../../../domain/OldTagManager';
import TagManager from '../../../../domain/TagManager';
import ClientFactory from '../../../../services/algolia/ClientFactory';
import GtmService from '../../../../services/gtm';
import OldGtmService from '../../../../services/oldGtm';
import { Product } from '../../../../types/algolia/product';
import { Context } from '../../../../types/frontastic';
import Button from '../../../atoms/button/Button';
import LoadingFullScreen from '../../../atoms/loadingFullScreen';

type Props = {
  context: Context;
  objectId: string | null;
  nameCategory: string;
  blogTiles: any;
  wishlistState: object;
  refererStore: any;
  isDisplaySidebar: boolean;
  gtmCategoryName: string;
  gtmCategoryId: string;
  hasSidebarCategory: boolean;
} & StateResultsProvided &
  InfiniteHitsProvided<Hit<Product>> &
  WrappedComponentProps;

type State = {
  isActiveScroll: boolean;
  colors: any;
};
const LIMIT_PRODUCT_PER_LOAD = 48;

class Hits extends Component<Props, State> {
  state = {
    isActiveScroll: false,
    colors: {},
  };

  protected sentinel: HTMLElement | null = null;

  protected observer: IntersectionObserver | null = null;

  public componentDidUpdate() {
    const { hits } = this.props;
    const { colors } = this.state;
    const { context } = this.props;
    const modelKeys = [];

    hits.forEach((hit) => {
      const productColors = colors[hit.root_model_key] ?? null;
      if (productColors == null) {
        colors[hit.root_model_key] = [];
        modelKeys.push(`root_model_key: ${hit.root_model_key}`);
      }
    });

    if (modelKeys.length > 0) {
      this.setState({ colors });
      ClientFactory.getColors(context, modelKeys).then((response) => {
        // @ts-ignore
        response.results[0]?.hits.forEach((hitColor: any) => {
          const hitColors = colors[hitColor.root_model_key] ?? [];
          hitColors.push(hitColor);
          colors[hitColor.root_model_key] = hitColors;
        });

        this.setState({ colors });
      });
    }
  }

  public componentDidMount() {
    const categoryName = this.props.gtmCategoryName;
    const categoryId = this.props.gtmCategoryId;

    if (categoryName && categoryId) {
      const gtmService = new GtmService(this.props.hits);
      const productListGTM = gtmService.createProductListGTM(categoryName, categoryId);

      TagManager.productImpressions(productListGTM, categoryName, categoryId);
      TagManager.hitViewdImpressions();

      // for universal analytics ( will be removed )
      const oldGtmService = new OldGtmService(this.props.hits);
      const oldProductListGTM = oldGtmService.createProductListGTM(`Page: ${categoryName}`);
      OldTagManager.productImpressions(oldProductListGTM);
      OldTagManager.hitViewdImpressions();
    }
  }

  public componentWillUnmount() {
    this.observer?.disconnect();
  }

  handleActiveScroll = () => {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    if (this.sentinel) {
      this.observer.observe(this.sentinel);
    }

    this.setState(() => ({
      isActiveScroll: true,
    }));

    this.props.refineNext();
  };

  onSentinelIntersection = (entries) => {
    const { hasMore, refineNext } = this.props;

    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  handleCount = () => {
    const { searchResults } = this.props;
    const count =
      (searchResults.page + 1) * LIMIT_PRODUCT_PER_LOAD - searchResults.nbHits >= 0
        ? searchResults.nbHits
        : (searchResults.page + 1) * LIMIT_PRODUCT_PER_LOAD;
    return +count;
  };

  renderLoadMoreText = () => {
    const { searchResults } = this.props;
    if (searchResults.nbHits - this.handleCount() < LIMIT_PRODUCT_PER_LOAD) {
      const productLeft = searchResults.nbHits - this.handleCount();
      return <FormattedMessage id={'filters.loadProductLeft'} values={{ num: productLeft }} />;
    }
    return <FormattedMessage id={'filters.loadMore.products'} values={{ num: LIMIT_PRODUCT_PER_LOAD }} />;
  };

  renderBtnLoadMore = () => {
    const { hasMore, searching, intl, refineNext } = this.props;
    const { isActiveScroll } = this.state;

    if (!hasMore) {
      return null;
    }

    if (isActiveScroll) {
      return searching ? (
        <div className={'mt-5 flex justify-center'}>
          <LoadingIcon width={30} height={30} />
        </div>
      ) : null;
    }

    return (
      <div className={'flex justify-center'}>
        <Button
          type={'button'}
          onClick={() => refineNext()}
          className={'btn btn-secondary btn-load-more'}
          ariaLabel={this.renderLoadMoreText()}
        >
          {searching ? <LoadingIcon width={30} height={30} /> : this.renderLoadMoreText()}
        </Button>
        <Button
          type={'button'}
          onClick={this.handleActiveScroll}
          className={'btn btn-default btn-load-more'}
          ariaLabel={intl.formatMessage({ id: 'filters.loadAll' })}
        >
          {searching ? <LoadingIcon width={30} height={30} /> : <FormattedMessage id={'filters.loadAll'} />}
        </Button>
      </div>
    );
  };

  handlePreviousLoad = () => {
    this.props.refinePrevious();
  };

  renderBtnPreviousLoad = () => (
    <div className={'container-previous-load'}>
      <Button
        type={'button'}
        onClick={() => this.handlePreviousLoad()}
        className={'btn btn-secondary btn-load-more'}
        ariaLabel={this.props.intl.formatMessage({ id: 'filters.previousLoad' })}
      >
        <FormattedMessage id={'filters.previousLoad'} />
      </Button>
    </div>
  );

  render() {
    const {
      searching,
      nameCategory,
      searchResults,
      blogTiles,
      context,
      refererStore,
      isDisplaySidebar,
      hasSidebarCategory,
      hasPrevious,
    } = this.props;

    // @ts-ignore
    const { algoliaIndexName } = context?.projectConfiguration || {};
    const hits = bindColors({
      hits: this.props.hits,
      colors: this.state.colors,
      wishlist: this.props.wishlistState,
      store: refererStore,
    });
    let initPositionBlog = 10;

    if (!searchResults || !searchResults.nbHits) {
      return null;
    }

    return (
      <div className="product-list--wrapper product-list-algolia">
        {searching && <LoadingFullScreen />}
        <div className="product-list--component">
          {hasPrevious && this.renderBtnPreviousLoad()}

          <div
            className={classnames('product-teaser-list', {
              'xl:grid-cols-5': !isDisplaySidebar,
            })}
          >
            {hits.map((hit, index) => {
              const position: number = index + 1;

              if (blogTiles && blogTiles.length && index === initPositionBlog) {
                const blogTilesByPos = blogTiles[Math.floor(initPositionBlog % 10)];
                initPositionBlog += 11;

                if (!blogTilesByPos) {
                  return null;
                }

                const { id, url, image, title } = blogTilesByPos || {};

                return (
                  <React.Fragment key={hit.product_id}>
                    <ProductTile
                      key={hit.productId}
                      product={hit}
                      position={position}
                      nameCategory={nameCategory}
                      colors={hit.colors}
                      refererStore={refererStore}
                      algoliaIndexName={algoliaIndexName}
                      hasSidebarCategory={hasSidebarCategory}
                      isDisplaySidebar={isDisplaySidebar}
                    />
                    {url && (
                      <Link to={{ pathname: url }} target={'_blank'} key={id}>
                        <div className="blog-tile">
                          <img src={image.url} alt={title} />
                          <div className="blog-tile--content">
                            <h3>{title}</h3>
                          </div>
                        </div>
                      </Link>
                    )}
                  </React.Fragment>
                );
              }

              return (
                <ProductTile
                  key={hit.product_id}
                  position={position}
                  product={hit}
                  nameCategory={nameCategory}
                  colors={hit.colors}
                  algoliaIndexName={algoliaIndexName}
                  refererStore={refererStore}
                  hasSidebarCategory={hasSidebarCategory}
                  isDisplaySidebar={isDisplaySidebar}
                />
              );
            })}
            <div className="ais-InfiniteHits-sentinel" ref={(c) => (this.sentinel = c)} />
          </div>
          <div className="product-list--load-more">
            <div className="product-status">
              <FormattedMessage
                id={'filters.productStatus'}
                values={{ count: this.handleCount(), total: searchResults.nbHits }}
              />
            </div>

            <div className="progress-bar">
              <span style={{ width: `${(this.handleCount() / searchResults.nbHits) * 100}%` }} />
            </div>
            {this.renderBtnLoadMore()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  objectId: state['product-list-algolia'].objectId,
}))(connectInfiniteHits(connectStateResults(injectIntl(Hits))));
